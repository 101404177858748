<template>
  <div
    class="page-content"
    :class="{
      'mise-en-page-bureau': estEcranBureau,
      'mise-en-page-mobile': !estEcranBureau
    }"
  >
    <!-- MENU SETTING -->

    <div class="menu" :class="{ 'width-menu': !openMenuSetting }">
      <!-- RESPONSIVE BUREAU -->
      <div v-if="estEcranBureau" class="menu-links">
        <div class="menu-title">Gestion des entités</div>
        <router-link
          v-if="(currentUser && currentUser.isSuper) || computedRegie"
          class="menu-item"
          to="/setting/users"
        >
          Gérer les utilisateurs
        </router-link>
        <template v-if="currentUser && currentUser.isSuper">
          <router-link class="menu-item" to="/setting/teams">
            Équipes
          </router-link>
          <router-link class="menu-item" to="/setting/deposits">
            Agences
          </router-link>
          <router-link class="menu-item" to="/setting/visibility">
            Visibilités
          </router-link>
          <router-link class="menu-item" to="/setting/connection">
            Connexions
          </router-link>
          <div class="menu-title">PIXEL</div>
          <router-link class="menu-item" to="/setting/typePixel">
            Types des Pixels
          </router-link>
          <div class="menu-title">Gestion des projets</div>
          <router-link class="menu-item" to="/setting/projects-types">
            Types des projets
          </router-link>
          <router-link class="menu-item" to="/setting/champs-personnalises">
            Champs personnalisés
          </router-link>
          <router-link class="menu-item" to="/setting/categorie-contact">
            Catégories
          </router-link>
          <router-link class="menu-item" to="/setting/pipeline">
            Pipelines
          </router-link>
          <router-link class="menu-item" to="/setting/sources">
            Sources
          </router-link>
          <router-link class="menu-item" to="/setting/precarite">
            Précarités
          </router-link>
          <router-link class="menu-item" to="/setting/visibility-project">
            Visibilités des projets
          </router-link>
          <!-- <router-link class="menu-item" to="/setting/affectation-leads">
            Affectation des leads
          </router-link> -->
          <router-link class="menu-item" to="/setting/templates">
            Templates
          </router-link>
          <router-link class="menu-item" to="/setting/history-export-excel">
            Historique export excel
          </router-link>
          <!-- <router-link class="menu-item" to="/setting/history-planning">
            Historique Planning
          </router-link> -->
          <!-- <router-link class="menu-item" to="/setting/webhook">
            Webhooks
          </router-link> -->
          <router-link class="menu-item" to="/setting/api">
            API token
          </router-link>
        </template>
      </div>
      <!-- RESPONSIVE NOT BUREAU (TABLET/PHONE) -->
      <template v-else>
        <div class="menu-setting" @click="menuSetting">
          <font-awesome-icon icon="bars" class="icon-bars" />
        </div>
        <!-- RESPONSIVE BUREAU -->
        <div class="menu-links" v-if="openMenuSetting">
          <div class="menu-title">Gestion des entités</div>
          <div @click="menuSetting">
            <router-link
              v-if="(currentUser && currentUser.isSuper) || computedRegie"
              class="menu-item"
              to="/setting/users"
            >
              Gérer les utilisateurs
            </router-link>
          </div>
          <template v-if="currentUser && currentUser.isSuper">
            <div @click="menuSetting">
              <router-link class="menu-item" to="/setting/teams">
                Équipes
              </router-link>
            </div>
            <div @click="menuSetting">
              <router-link class="menu-item" to="/setting/deposits">
                Agences
              </router-link>
            </div>
            <div @click="menuSetting">
              <router-link class="menu-item" to="/setting/visibility">
                Visibilités
              </router-link>
            </div>
            <div @click="menuSetting">
              <router-link class="menu-item" to="/setting/connection">
                Connexions
              </router-link>
            </div>
            <div class="menu-title">PIXEL</div>
            <div @click="menuSetting">
              <router-link class="menu-item" to="/setting/typePixel">
                Types des Pixels
              </router-link>
            </div>
            <div class="menu-title">Gestion des projets</div>
            <div @click="menuSetting">
              <router-link class="menu-item" to="/setting/projects-types">
                Types des projets
              </router-link>
            </div>
            <div @click="menuSetting">
              <router-link class="menu-item" to="/setting/champs-personnalises">
                Champs personnalisés
              </router-link>
            </div>
            <div @click="menuSetting">
              <router-link class="menu-item" to="/setting/categorie-contact">
                Catégories
              </router-link>
            </div>
            <div @click="menuSetting">
              <router-link class="menu-item" to="/setting/pipeline">
                Pipelines
              </router-link>
            </div>
            <div @click="menuSetting">
              <router-link class="menu-item" to="/setting/sources">
                Sources
              </router-link>
            </div>
            <div @click="menuSetting">
              <router-link class="menu-item" to="/setting/precarite">
                Précarités
              </router-link>
            </div>
            <div @click="menuSetting">
              <router-link class="menu-item" to="/setting/visibility-project">
                Visibilités des projets
              </router-link>
            </div>
            <!-- <div @click="menuSetting">
              <router-link class="menu-item" to="/setting/affectation-leads">
                Affectation des leads
              </router-link>
            </div> -->
            <div @click="menuSetting">
              <router-link class="menu-item" to="/setting/templates">
                Templates
              </router-link>
            </div>
            <div @click="menuSetting">
              <router-link class="menu-item" to="/setting/history-export-excel">
                Historique export excel
              </router-link>
            </div>
            <!-- <div @click="menuSetting">
              <router-link class="menu-item" to="/setting/history-planning">
                Historique Planning
              </router-link>
            </div> -->
            <!-- <div @click="menuSetting">
              <router-link class="menu-item" to="/setting/webhook">
                Webhooks
              </router-link>
            </div> -->
            <div @click="menuSetting">
              <router-link class="menu-item" to="/setting/api">
                API token
              </router-link>
            </div>
          </template>
        </div>
      </template>
    </div>
    <!-- CONTENT SETTING -->
    <div class="content" :class="{ 'width-content': !openMenuSetting }">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Admin',
  data() {
    return { estEcranBureau: true, openMenuSetting: false }
  },
  methods: {
    menuSetting() {
      this.openMenuSetting = !this.openMenuSetting
    },
    onResize() {
      this.estEcranBureau = window.innerWidth >= 827 // ajustez le point de rupture selon vos besoins
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    computedRegie: function() {
      return (
        this.currentUser &&
        (this.currentUser.type == 'user.commercial' ||
          this.currentUser.type == 'user.final' ||
          (this.currentUser.type == 'user.admin' &&
            this.currentUser.details &&
            this.currentUser.details.team &&
            this.currentUser.details.team.data &&
            this.currentUser.details.team.data.type === 'regie'))
      )
    }
  },
  mounted() {
    window.addEventListener('resize', this.onResize)
    this.onResize()
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize)
  }
}
</script>

<style lang="scss" scoped>
.page-content {
  position: relative;
  padding: 16px;
  height: 100%;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  .menu::-webkit-scrollbar {
    height: 7px;
    width: 7px;
  }
  .menu::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #80808054;
    border-radius: 10px;
  }
  .menu::-webkit-scrollbar-thumb {
    background-color: #afb1b3;
    border-radius: 10px;
  }
  .menu::-webkit-scrollbar-thumb:hover {
    background: #888;
  }
  .menu {
    cursor: pointer;
    white-space: normal;
    max-height: 100%;
    height: 94%;
    overflow-y: auto;
    overflow-x: hidden;
    // width: 100%;
    // margin-right: 2px;
    margin-top: 4px;
    // margin-left: -38px;
    // margin-right: -83px;
    padding: 3px 4px;
  }
  .menu {
    position: absolute;
    width: 250px;
    padding-right: 15px;

    .menu-links {
      // padding: 0.375rem 1.75rem 0.375rem 0.75rem;
      font-weight: 600;
      font-size: 13px;
      .menu-title {
        margin: 5px 0;
        font-size: 13px;
        text-transform: uppercase;
        color: #26292cad;
      }

      .menu-item {
        display: block;
        font-size: 13px;
        color: #495057;
        margin: 4px 0px;
        padding: 6px 10px;
        border-radius: 2px;
        text-decoration: none;
        &:hover {
          background-color: #49505726;
        }
        &.router-link-active {
          color: #2dabe2;
          background-color: #2dabe226;
        }
      }
    }
  }
  .content {
    display: block;
    padding: 20px;
    height: 100%;
    margin-left: 250px;
    overflow-y: auto;
    border: 1px solid #e5e5e5;
    background-color: #fff;
    border-radius: 3px;
    box-sizing: border-box;
  }
}
.mise-en-page-bureau {
  /* styles spécifiques au bureau ici */
  .menu-setting {
    display: none;
  }
}

.mise-en-page-mobile {
  /* styles spécifiques au mobile ici */
  .menu {
    // background-color: #eeeeee;
    z-index: 4;
    margin-top: 0px;
    width: 194px;
    .menu-setting {
      display: block;
      width: 33px;
      left: 44%;
      cursor: pointer;
      height: 32px;
      border-radius: 35px;
      top: 0px;
      position: absolute;
      background-color: #6c757d6e;
      .icon-bars {
        left: 10px;
        top: 8px;
        position: absolute;
      }
    }
    &.width-menu {
      width: 43px;
      // margin-left: 4px;
      // margin-top: -18px;
      position: absolute;
      left: 50%;
      top: -3px;
      height: 45px;
      .menu-setting {
        left: 0px;
        cursor: pointer;
        height: 33px;
        top: 8px;
        border-radius: 31px;
        position: absolute;
        color: white;
        background-color: rgb(64 67 70);
        .icon-bars {
          left: 10px;
          top: 8px;
          position: absolute;
        }
      }
    }

    .menu-links {
      margin-top: 39px;
    }
  }
  .content {
    margin-left: 195px;
    &.width-content {
      height: 96%;
      margin-top: 26px;
      margin-left: 4px;
      position: relative;
      animation-name: move;
      animation-duration: 0.5s;
      animation-direction: alternate;

      @keyframes move {
        from {
          left: 12px;
        }
        to {
          left: 0px;
        }
      }
    }
  }
  @media screen and (max-width: 450px) {
    .menu {
      text-align: center;
      background-color: #eeeeee;
      width: 94%;
      height: 96%;
      &.width-menu {
        left: 44%;
      }
    }
  }
}
</style>
